import type {
  ProductFilters,
  ListResponse,
  PartialProduct,
  Product,
} from "./types";
import { getParamStringFromObject } from "~/utils/helpers";
import { getSession } from "~/services/session";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

// export const listProducts = async <T extends ProductFilters["fields"]>(
//   filters?: ProductFilters & { fields?: T }
// ): Promise<ListResponse<PartialProduct<T>>> => {
//   const params = filters ?  getParamStringFromObject(filters) : "";
//   const response = await fetch(
//     `${import.meta.env.VITE_ROMA_API}/products${params}`
//   );

//   if (!response.ok) {
//     // TODO
//     throw new Error();
//   }

//   // throw new Error("error thrown in listProducts()");
//   const data: ListResponse<PartialProduct<T>> = await response.json();
//   return data;
// };

// ! refactor above - not properly returning the type as intended.
// intent was if 'fields' are passinto into the product filters, the returned would be a Partial/Pick of Product with the
// defined fields. Didn't seem to work properly, and there are inconsistencies between the fields passed into query, and
// the fields returned in the response. Below just says that product will always return with the full product structure.

export const _listProducts = async (
  filters?: ProductFilters
): Promise<ListResponse<Product>> => {
  // TODO - This fetch can be authenticated or unauthenticated, determine how to best handle
  let unauthenticated = true;
  const session = await getSession();
  if (session?.token) {
    unauthenticated = false;
  }

  const params = filters ? getParamStringFromObject(filters) : "";

  // await simulateApiError({path: `/products${params}`})

  const data = await fetchAPI2<ListResponse<Product>>(`/products${params}`, {
    unauthenticated,
  });


  return data;
};
