import { query } from "@solidjs/router";
import { ProductFilters } from "../roma-api/products/types";
import { _listProducts } from "../roma-api/products/";


export const getProducts = query(async (filters?: ProductFilters) => {
  return await _listProducts(filters);
}, "products"); 

// deprecated - useProducts should return the createAsync. TODO
export const useProducts = query(async (filters?: ProductFilters) => {
  return await _listProducts(filters);
}, "products");